<template>
    <section class="container .explain-view py-3 my-lg-3 my-xl-4 my-xxl-5">
        <div class="row pt-2 py-md-4">
            <div class="col-12">
                <h2>รับ ขายฝาก จำนอง บ้าน คอนโด ที่ดิน ทั่ว ประเทศ โฉนดแลกเงิน</h2>
                <b>“LandForLoan” คือตัวเลือกที่คุณไม่ควรพลาด มั่นใจว่าคุณจะได้รับความเชี่ยวชาญ, ความรวดเร็ว,
                    และความมั่นใจในบริการที่คุณต้องการและคุณสมบัติ.</b>
            </div>

        </div>
        <div class="row pt-2 py-md-4 explain-view-two">
            <div class="col-md-12 pb-4">
                <h4 class="">ข้อดีของการ รับจำนอง-ขายฝาก หรือ ร่วมลงทุนกับ Interbank Credit</h4>
            </div>
            <div class="col-md-6">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="60"
                    height="60" x="0" y="0" viewBox="0 0 464 464" style="enable-background:new 0 0 512 512"
                    xml:space="preserve" class="">
                    <g>
                        <path fill="#4398d1"
                            d="M408 112H56c-17.672 0-32 14.328-32 32v304h416V144c0-17.672-14.328-32-32-32zm0 0" opacity="1"
                            data-original="#4398d1"></path>
                        <path fill="#87ced9" d="M392 144H64a8 8 0 0 0-8 8v280h352V160c0-8.836-7.164-16-16-16zm0 0"
                            opacity="1" data-original="#87ced9" class=""></path>
                        <path fill="#71c4d1" d="M408 200 176 432h232zm0 0" opacity="1" data-original="#71c4d1" class="">
                        </path>
                        <path fill="#fdb62f"
                            d="m344 0 24.848 27.273L404 16.078l7.879 36.043L447.922 60l-11.195 35.152L464 120l-27.273 24.848L447.922 180l-36.043 7.879L404 223.922l-35.152-11.195L344 240l-24.848-27.273L284 223.922l-7.879-36.043L240.078 180l11.195-35.152L224 120l27.273-24.848L240.078 60l36.043-7.879L284 16.078l35.152 11.195zm0 0"
                            opacity="1" data-original="#fdb62f"></path>
                        <g fill="#fd6d2f">
                            <path
                                d="m298.344 154.344 79.996-80 11.316 11.312-80 80zM304 120c-13.254 0-24-10.746-24-24s10.746-24 24-24 24 10.746 24 24-10.746 24-24 24zm0-32a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM384 168c-13.254 0-24-10.746-24-24s10.746-24 24-24 24 10.746 24 24-10.746 24-24 24zm0-32a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 0"
                                fill="#fd6d2f" opacity="1" data-original="#fd6d2f"></path>
                        </g>
                        <path fill="#e0e3e6"
                            d="m140.602 334.39-28.739 11.657L130.617 384h31.473zM240 336l-99.398-1.61-21.48-42.519 78.765-77.95zm0 0"
                            opacity="1" data-original="#e0e3e6" class=""></path>
                        <path fill="#e3584b"
                            d="M89.191 330.574a23.215 23.215 0 0 0 13.157 13.977 23.207 23.207 0 0 0 19.18-.719l19.074-9.441-21.48-42.52-19.876 11.8c-9.285 5.466-13.48 16.688-10.055 26.903zm0 0"
                            opacity="1" data-original="#e3584b"></path>
                        <path fill="#5eb3d1"
                            d="m241.281 266.04 26.457-9.196 5.246 15.105-26.453 9.196zM220.492 212.855l17.645-18.39 11.547 11.078-17.649 18.39zM264 320h24v16h-24zm0 0"
                            opacity="1" data-original="#5eb3d1" class=""></path>
                        <path fill="#e3584b" d="M312 272h152v96H312zm0 0" opacity="1" data-original="#e3584b"></path>
                        <path fill="#c9433c" d="M336 296h32v16h-32zM384 296h56v16h-56zM336 328h104v16H336zm0 0" opacity="1"
                            data-original="#c9433c"></path>
                        <path fill="#fd6d2f" d="M0 176h120v64H0zm0 0" opacity="1" data-original="#fd6d2f"></path>
                        <path fill="#e3584b" d="M24 200h16v16H24zM56 200h40v16H56zm0 0" opacity="1" data-original="#e3584b">
                        </path>
                        <path fill="#d6d9db"
                            d="M440 464H24c-13.254 0-24-10.746-24-24v-24h183.055c5.484 0 10.496 3.098 12.945 8s7.46 8 12.945 8h46.11c5.484 0 10.496-3.098 12.945-8s7.46-8 12.945-8H464v24c0 13.254-10.746 24-24 24zm0 0"
                            opacity="1" data-original="#d6d9db"></path>
                        <path fill="#71c4d1" d="M120 192v48H56v16h80v-64zm0 0" opacity="1" data-original="#71c4d1" class="">
                        </path>
                        <path fill="#3e8cc7" d="M24 240h32v16H24zM408 368h32v16h-32zm0 0" opacity="1"
                            data-original="#3e8cc7" class=""></path>
                    </g>
                </svg>
            <h4 class="explain-view-two-h4">ขายฝาก กับ Interbank Credit</h4>
            <p class="mb-0">Find aute irure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                neque congue aliqua dolor do amet sint ovar velit officia reprehenderit in voluptate pariatur.</p>
            <ul class="mt-3">
                <li class="mb-3"><b class="me-2">ไม่เช็คประวัติทางการเงิน:</b>ไม่เช็คเครดิตบูโร
                    ไม่ต้องใช้สลิปหรือเช็คประวัติการเงิน และไม่จำเป็นต้องใช้คนค้ำประกัน</li>
                <li class="mb-3"><b class="me-2">ผู้ขายฝาก:</b>ดอกเบี้ยเริ่มต้น 0.75%/เดือน วงเงินมีโอกาสได้สูงถึง 70%
                </li>
                <li class="mb-3"><b class="me-2">ไม่ต้องการยึดทรัพย์:</b>นายทุนรับขายฝากที่ดินมีคุณภาพ มีความเป็นธรรม
                    ต้องการดอกเบี้ยเป็นหลัก ไม่ต้องการยึดทรัพย์</li>
            </ul>
        </div>
        <div class="col-md-6">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="60"
                height="60" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512"
                xml:space="preserve" class="">
                <g>
                    <path fill="#8bc34a"
                        d="M125.5 6.633a4 4 0 0 0-3.885-3.885l-27.9-.8a4 4 0 0 0-2.943 6.827l5.029 5.029L64 45.615 45.692 27.308a13 13 0 0 0-17.365-.917L3.728 46.279A6 6 0 0 0 1.5 50.945v22.193a4 4 0 0 0 6.515 3.11l27.563-22.285 19.23 19.229a13 13 0 0 0 18.384 0l41-41 5.279 5.279a4 4 0 0 0 6.827-2.943z"
                        opacity="1" data-original="#8bc34a" class=""></path>
                    <circle cx="80" cy="65" r="32.5" fill="#ffb300" opacity="1" data-original="#ffb300"></circle>
                    <circle cx="80" cy="65" r="23.5" fill="#ffa000" opacity="1" data-original="#ffa000"></circle>
                    <path fill="#ffccbc"
                        d="M122.315 84.065a240.575 240.575 0 0 1-26.97 18.9c-9.61 5.76-28.21 9.38-38.69 7.81a11.954 11.954 0 0 0-6.39.83l-25.6 10.88a12 12 0 0 1-15.7-6.273l-3.445-7.951a12 12 0 0 1 6.345-15.827L43.4 79.125s15.81-7.98 31.38-2.02c10.91 4.17 14.69 1.13 19.43.04 3.06-.7 5.72 2.42 5.97 5.5 2.19-1.55 10.93-7.73 14.17-9.91a6.929 6.929 0 0 1 7.97 11.33z"
                        opacity="1" data-original="#ffccbc"></path>
                    <path fill="#039be5"
                        d="m46.115 116.625-20.882 8.809a12 12 0 0 1-15.746-6.453l-5.653-13.61a12 12 0 0 1 6.44-15.671c8.117-3.4 17.863-7.482 21.271-8.9a3 3 0 0 1 3.93 1.64l12.25 30.3a3 3 0 0 1-1.61 3.885z"
                        opacity="1" data-original="#039be5"></path>
                    <path fill="#eceff1"
                        d="m46.115 116.625-5.69 2.42-14.86-35.76c2.31-.95 4.41-1.83 5.98-2.48a3 3 0 0 1 3.93 1.64l12.25 30.3a3 3 0 0 1-1.61 3.88z"
                        opacity="1" data-original="#eceff1"></path>
                    <path fill="#ffab91"
                        d="M103.165 82.326a9.265 9.265 0 0 0-.309-1.576c-1.174.829-2.128 1.5-2.676 1.892.377 3.718-4.979 7.394-7.5 8.509-3.1 1.413-9.462 1.432-13 .992a1.5 1.5 0 0 0-.37 2.978c4.237.508 10.894.447 14.593-1.233 2.788-1.231 9.877-5.709 9.262-11.562z"
                        opacity="1" data-original="#ffab91"></path>
                    <path fill="#ffb300"
                        d="M79.1 57.471h2.709a2.114 2.114 0 0 1 2.111 2.112 1.5 1.5 0 0 0 3 0 5.117 5.117 0 0 0-5.111-5.112H81.5v-2.112a1.5 1.5 0 0 0-3 0V54.5a6.014 6.014 0 0 0 .6 12h1.8a3.015 3.015 0 0 1 0 6.029h-2.706a2.114 2.114 0 0 1-2.111-2.112 1.5 1.5 0 0 0-3 0 5.117 5.117 0 0 0 5.111 5.112h.306v2.112a1.5 1.5 0 1 0 3 0V75.5a6.014 6.014 0 0 0-.6-12h-1.8a3.015 3.015 0 0 1 0-6.029z"
                        opacity="1" data-original="#ffb300"></path>
                </g>
            </svg>
            <h4 class="explain-view-two-h4">ลงทุน กับ Interbank Credit</h4>
            <p class="mb-0">Find aute irure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                neque congue aliqua dolor do amet sint ovar velit officia reprehenderit in voluptate pariatur.</p>
            <ul class="mt-3">
                <li class="mb-3"><b class="me-2">ถูกต้องตามกฎหมาย:</b>ดำเนินสัญญาขายฝากถูกต้องตามกฎหมาย ณ สำนักงานที่ดิน
                </li>
                <li class="mb-3"><b class="me-2">ผลตอบแทน:</b>สินเชื่อ SME ให้ดอกเบี้ยสูงถึง 9 – 15% ต่อปี</li>
                <li class="mb-3"><b class="me-2">ประเมินราคาโดยผู้เชี่ยวชาญ:</b>พร้อมแนะนำบริษัทประเมินอสังหาริมทรัพย์
                    ที่ได้รับความเห็นชอบจาก กลต.</li>
            </ul>
        </div>
    </div>
</section></template>
  
<script lang="js">
// Options
import { Options, Vue } from "vue-class-component";
// Options
@Options({
    data() {
        return {};
    },
})
export default class Home_view extends Vue { }
</script>
  
<style>
    .margin-info-top {
        margin-top: 74px;
    }
    .explain-view-two-h4 {
        color: #2cabe1;
    }
    .explain-view-two b {
        color: #2cabe1;
    }
</style>