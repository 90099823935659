<template>
    <section class="section-bk-cp-60-deed container-info-img " style="background-image: linear-gradient(rgb(194 228 242 / 73%), rgb(206 240 255 / 80%)), url('/layouts/templates/roofing_info/assets/uploads/page/home/deed/deed-info.png');">
        <div class="container text-center">
            <div class="row">
                <div class="col-12 mb-4">
                    <h2>Interbank Credit</h2>
                    <h3>โฉนดแลกเงิน รับจำนองที่ดิน แปลงโฉนดเป็นทุน</h3>
                    <p>
                        เราช่วยต่อยอดให้ทุกคนสามารถแปลงโฉนดเป็นทุน เข้าถึงแหล่งเงินทุนที่ถูกกฎหมาย
                        <br>
                        และตอบโจทย์นักลงทุนรุ่นใหม่ที่สนใจลงทุนอสังหาริมทรัพย์
                    </p>
                </div>
                <div class="col-6">
                    <h3>การันตีคุณภาพ</h3>
                    <p>Matching วงเงินนักลงทุนกับทรัพย์ไปเเล้ว</p>
                    <h3>สถิติการยึดทรัพย์ ของ Interbank Credit</h3>
                    <h2 class="mb-2">0</h2>
                    <h3>เปอร์เซ็น</h3>
                    <b>ไร้กังวล หมดปัญหาการยึดทรัพย์ เพราะทาง Interbank Credit <br> ไม่มีนโยบายยึดทรัพย์</b>
                </div>
                <div class="col-6">
                    <h3>ทรัพย์ขายฝากผ่าน Interbank Credit รวมมูลค่ากว่า</h3>
                    <h2 class="mb-2">1,500,000,000</h2>
                    <h3>บาท</h3>
                    <h3>วงเงินจำนอง-ขายฝากคงเหลือ</h3>
                    <h2 class="mb-2">2,234,000,000</h2>
                    <h3>บาท</h3>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script lang="js">
// Options
import { Options, Vue } from "vue-class-component";
// Options
@Options({
  data() {
    return {};
  },
})
export default class Home_view extends Vue { }
</script>
  
<style>
  .margin-info-top {
    margin-top: 74px;
  }
</style>

<style>
    .section-bk-cp-60-deed {
        background-color: #2aaae130;
        padding: 60px 0px;
    }
    .section-bk-cp-60-deed h2 {
        color: #2cabe1;
    }
    .section-bk-cp-60-deed h3 {
        color: #005478;
    }
    .section-bk-cp-60-deed b {
        color: #2cabe1;
    }
    .container-info-img {
        /* background-image: linear-gradient(to bottom, #0366354f, #0366354f), url("https://static01.nyt.com/images/2020/12/14/well/14google-photo/14google-photo-videoSixteenByNineJumbo1600.jpg"); */
        background-size: 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
    }

    .container-info-img {
        padding: 60px 140px 94px;
        /* margin-top: 90px; */
        background-color: #0366354f;
    }

    @media screen and (max-device-width: 480px) and (orientation: portrait) {
        .container-info-img {
            padding: 56px 20px 53px;
        }
        .container-info-img {
            background-size: 350%;
            background-repeat: revert;
            background-attachment: inherit;
            background-position: center;
        }
    }
</style>
  