<template>
    <div>
      <FullPageLoader></FullPageLoader>
      <ModalPageOne></ModalPageOne>
      <main class="page-wrapper">
        <ModalPageTwo></ModalPageTwo>
        <HeaderPageOne></HeaderPageOne>
        <MobileMenuPageOne></MobileMenuPageOne>
        <div>
          <carousel :slides="slides" :interval="3000" controls indicators></carousel>
          <!-- <InfoView></InfoView> -->
          <DeedView></DeedView>
          <ExplainView></ExplainView>
          <TrainView></TrainView>
          <ServeView></ServeView>
          <ExperienceView></ExperienceView>
          <WealthView></WealthView>
          <HelpView></HelpView>
          <MapView></MapView>
        </div>
      </main>
      <FooterPageOne></FooterPageOne>
    </div>
  </template>
  
  <script lang="js">
    import FullPageLoader from '@/components/layouts/templates/roofing_info/FullPageLoader.vue'; 
    import HeaderPageOne from '@/components/layouts/templates/roofing_info/HeaderPageOne.vue'; 
    // import MobileMenuPageOne from '@/components/layouts/templates/roofing_info/MobileMenuPageOne.vue'; 
    import FooterPageOne from '@/components/layouts/templates/roofing_info/FooterPageOne.vue'; 
    import ModalPageOne from '@/components/layouts/templates/roofing_info/ModalPageOne.vue'; 
    import ModalPageTwo from '@/components/layouts/templates/roofing_info/ModalPageTwo.vue'; 
    // view
    import InfoView from '../home/view/contact/Info_view.vue'; 
    import ServeView from '../home/view/contact/Serve_view.vue'; 
    import DeedView from '../home/view/contact/Deed_view.vue'; 
    import ExplainView from '../home/view/contact/Explain_view.vue'; 
    import TrainView from '../home/view/contact/Train_view.vue'; 
    import ExperienceView from '../home/view/contact/Experience_view.vue'; 
    import WealthView from '../home/view/contact/Wealth_view.vue'; 
    import HelpView from '../home/view/contact/Help_view.vue'; 
    import MapView from '../home/view/contact/Map_view.vue'; 
    // components
    import Carousel from "../home/components/carousel/Carousel.vue";
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
      data() {
          return {
            slides: [
              "/layouts/templates/roofing_info/assets/uploads/page/home/info/info-top-1.png?vp=001",
              "/layouts/templates/roofing_info/assets/uploads/page/home/info/info-top-2.png?vp=001",
              "/layouts/templates/roofing_info/assets/uploads/page/home/info/info-top-3.png?vp=001",
              // "https://picsum.photos/id/1033/900/400",
              // "https://picsum.photos/id/1037/900/400",
              // "https://picsum.photos/id/1035/900/400",
              // "https://picsum.photos/id/1036/900/400",
            ],
          };
      },
      // mounted() {
      //   // seoTitle
      //   var seoTitle = 'Interbank Credit โฉนดแลกเงิน รับจำนอง-ขายฝากที่ดิน บ้าน คอนโด';
      //   if (seoTitle) {
      //     document.title = seoTitle;
      //   }
      //   // seo meta
      //   var seoDataMeta = '';
      //   var seoDataMeta = [
      //     {
      //       'tag': 'meta',
      //       'name': 'description',
      //       'content': 'รับจำนอง-ขายฝาก บ้าน ที่ดิน คอนโด ทั่วประเทศไทย ให้วงเงินสูง ดอกเบี้ยต่ำสุด 0.75% ต่อเดือน อนุมัติไวภายใน 24 ชั่วโมง ไม่เช็คเครดิตบูโร ไม่เช็คแบล็คลิสต์',
      //     },
      //     {
      //       'tag': 'meta',
      //       'name': 'keywords',
      //       'content': 'keywords, ****, ****',
      //     },
      //     {
      //       'tag': 'link',
      //       'name': 'canonical',
      //       'content': (window.location.href) ? window.location.href : '',
      //     },
      //   ];
      //   seoDataMeta.forEach(mySeoMeta);
      //   function mySeoMeta(item, index) {
      //     const metaGet = document.createElement(item.tag);
      //     if (item.tag == 'meta') {
      //       document.querySelector(item.tag + '[name="'+ item.name +'"]').setAttribute("content", item.content);
      //     }
      //     if (item.tag == 'link') {
      //       document.querySelector(item.tag + '[rel="'+ item.name +'"]').setAttribute("href", item.content);
      //     }
      //   }
      // },
      components: {
          FullPageLoader,
          HeaderPageOne,
          // MobileMenuPageOne,
          FooterPageOne,
          ModalPageOne,
          ModalPageTwo,
          // components view ----
          Carousel,
          // view ----
          InfoView,
          DeedView,
          ExplainView,
          TrainView,
          ServeView,
          ExperienceView,
          WealthView,
          HelpView,
          MapView,

      },
      methods: {},
      created() {},
    })
    export default class Home_view extends Vue {}
  </script>

  