<template>
  <section class="pt-5 pb-5 mt-3 mb-3 youtube-r-15 sectyoutubeion-youtube-bk-cp-40">
    <div class="container ">
      <div class="row">
        <div class="col-md-7">
          <h2>ประสบการณ์ผู้ขายฝากกับ Interbank Credit</h2>
          <h4>"อนุมัติเงินไว ทันใจ" คุณปาล์ม พณิช เรืองไกร อาชีพ รับเหมาก่อสร้าง</h4>
          <p>ไม่มีประสบการณ์ในการขายฝากเลย Interbank Credit พนักงานแนะนำดีมาก ตอบทุกคำถามชัดเจน ได้รับเงินรวดเร็วทันใจ เชื่อมั่นในบริษัท เพราะเชื่อมั่นในตัวคุณแพรรินทร์อยู่แล้ว ที่สำคัญไม่มีนโยบายยึดทรัพย์</p>
          <div class="row mt-4">
            <div class="col-md-4 mb-md-0 mb-4">
              <iframe width="560" height="130" src="https://www.youtube.com/embed/D_ToJbrFYdI?si=Yxxlf7jPoUs4rLaV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-md-4 mb-md-0 mb-4">
              <iframe width="560" height="130" src="https://www.youtube.com/embed/UPlO31RpwJo?si=erA3Ps_ePKrAR1zL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-md-4 mb-md-0 mb-4">
              <iframe width="560" height="130" src="https://www.youtube.com/embed/NlAC30Vy5_Q?si=Ks1AtwHry5OLsFn7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <iframe width="560" height="310" src="https://www.youtube.com/embed/MBGGFX8g9IY?si=LwPw1Qd57sl8_Gh9"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script lang="js">
// Options
import { Options, Vue } from "vue-class-component";
// Options
@Options({
  data() {
    return {};
  },
})
export default class Home_view extends Vue { }
</script>

<style>
  .sectyoutubeion-youtube-bk-cp-40 {
      background-color: #2aaae130;
      padding: 40px 0px;
  }
  .youtube-r-15 iframe {
    border-radius: 15px !important;
  }
  .sectyoutubeion-youtube-bk-cp-40 h4 {
    color: #005478;
  }
  
</style>
  