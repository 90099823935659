

<template>
    <header class="navbar navbar-expand-lg fixed-top bg-light">
        <div class="container">
            <router-link class="navbar-brand pe-sm-3" to="/">
                <a>
                    <img src="/layouts/templates/roofing_info/assets/uploads/logo/logo.png" width="80" alt="">
                </a>
            </router-link>
            <!-- <div class="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto" data-bs-toggle="mode">
            <input class="form-check-input" type="checkbox" id="theme-mode">
            <label class="form-check-label" for="theme-mode">
                <i class="ai-sun fs-lg"></i>
            </label>
            <label class="form-check-label" for="theme-mode">
                <i class="ai-moon fs-lg"></i>
            </label>
            </div> -->
            <!-- <div class="nav align-items-center order-lg-3 ms-n1 me-3 me-sm-0">
            <a class="nav-link fs-4 p-2 mx-sm-1" href="#searchModal" data-bs-toggle="modal">
                <i class="ai-search"></i>
            </a>
            <a class="nav-link fs-4 p-2 mx-sm-1 d-none d-sm-flex" href="account-signin.html">
                <i class="ai-user"></i>
            </a>
            <a class="nav-link position-relative fs-4 p-2" href="#cartOffcanvas" data-bs-toggle="offcanvas">
                <i class="ai-cart"></i>
                <span class="badge bg-primary fs-xs position-absolute end-0 top-0 me-n1" style="padding: .25rem .375rem;">3</span>
            </a>
            </div> -->
            <div class="d-flex align-items-center order-lg-2 ms-auto">
                <a v-bind:href="'tel:' + envTelOne " class="d-flex align-items-center text-decoration-none">
                    <i class="ai-phone fs-3"></i>
                    <div class="text-nowrap ps-2 d-none d-lg-block">
                    <h6 class="fs-sm lh-1 mb-0">สายด่วน 24 ชม.</h6>
                    <span class="text-body fs-xs">(+66) {{envTelOne}}</span>
                    </div>
                </a>
                <a v-bind:href=" envLineURL " class="d-flex align-items-center text-decoration-none ms-4">
                    <i class="ai-messages fs-3"></i>
                    <div class="text-nowrap ps-2 d-none d-lg-block">
                    <h6 class="fs-sm lh-1 mb-0">Line ID</h6>
                    <span class="text-body fs-xs">ID's Line : {{envLineID}}</span>
                    </div>
                </a>
            </div>
            <button class="navbar-toggler ms-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span class="navbar-toggler-icon">
            </span>
            </button>
            <nav class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav navbar-nav-scroll me-auto" style="--ar-scroll-height: 520px;">
                <li class="nav-item">
                    <router-link class="nav-link .active" to="/">
                        <a >หน้าแรก</a>
                    </router-link>
                </li>
                <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Landings</a>
                <div class="dropdown-menu overflow-hidden p-0">
                    <div class="d-lg-flex">
                    <div class="mega-dropdown-column pt-1 pt-lg-3 pb-lg-4">
                        <ul class="list-unstyled mb-0">
                        <li>
                            <a class="dropdown-item" href="index.html">Template Intro Page</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 rounded-3 rounded-start-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/landings.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-mobile-app-showcase.html">Mobile App Showcase</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/mobile-app.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-product.html">Product Landing</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/product-landing.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-saas-v1.html">SaaS v.1</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/saas-1.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-saas-v2.html">SaaS v.2</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/saas-2.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-saas-v3.html">SaaS v.3
                            <span class="text-danger fs-xs ms-2">New</span>
                            </a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/saas-3.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-shop.html">Shop Homepage</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/shop-homepage.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-marketing-agency.html">Marketing Agency</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/marketing-agency.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-creative-agency.html">Creative Agency</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/creative-agency.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-conference.html">Conference (Event)
                            <span class="text-danger fs-xs ms-2">New</span>
                            </a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/conference.jpg);">
                            </span>
                        </li>
                        </ul>
                    </div>
                    <div class="mega-dropdown-column pb-2 pt-lg-3 pb-lg-4">
                        <ul class="list-unstyled mb-0">
                        <li>
                            <a class="dropdown-item" href="landing-web-studio.html">Web Studio</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/web-studio.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-corporate.html">Corporate</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/corporate.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-business-consulting.html">Business Consulting</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/business-consulting.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-coworking-space.html">Coworking Space</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/coworking.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-yoga-studio.html">Yoga Studio</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/yoga-studio.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-influencer.html">Influencer
                            <span class="text-danger fs-xs ms-2">New</span>
                            </a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/influencer.jpg);">
                            </span>
                        </li>
                        <li>
                            <a class="dropdown-item" href="landing-blog.html">Blog Homepage</a>
                            <span class="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0" style="background-image: url(/layouts/templates/roofing_info/assets/img/megamenu/blog-homepage.jpg);">
                            </span>
                        </li>
                        </ul>
                    </div>
                    <div class="mega-dropdown-column position-relative border-start zindex-3">
                    </div>
                    </div>
                </div>
                </li>
                <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Pages</a>
                <ul class="dropdown-menu">
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Portfolio</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="portfolio-list-v1.html">List View v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="portfolio-list-v2.html">List View v.2</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="portfolio-grid-v1.html">Grid View v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="portfolio-grid-v2.html">Grid View v.2</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="portfolio-slider.html">Slider View</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="portfolio-single-v1.html">Single Project v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="portfolio-single-v2.html">Single Project v.2</a>
                        </li>
                    </ul>
                    </li>
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Shop</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="shop-catalog.html">Catalog (Listing)</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="shop-single.html">Product Page</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="shop-checkout.html">Checkout</a>
                        </li>
                    </ul>
                    </li>
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="blog-grid-sidebar.html">Grid View with Sidebar</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="blog-grid.html">Grid View no Sidebar</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="blog-list-sidebar.html">List View with Sidebar</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="blog-list.html">List View no Sidebar</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="blog-single-v1.html">Single post v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="blog-single-v2.html">Single post v.2</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="blog-single-v3.html">Single post v.3</a>
                        </li>
                    </ul>
                    </li>
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">About</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="about-agency.html">About - Agency</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="about-product.html">About - Product</a>
                        </li>
                    </ul>
                    </li>
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="services-v1.html">Services v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="services-v2.html">Services v.2</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="services-v3.html">Services v.3</a>
                        </li>
                    </ul>
                    </li>
                    <li>
                    <a class="dropdown-item" href="pricing.html">Pricing</a>
                    </li>
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Contacts</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="contacts-v1.html">Contacts v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="contacts-v2.html">Contacts v.2</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="contacts-v3.html">Contacts v.3</a>
                        </li>
                    </ul>
                    </li>
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Specialty Pages</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="404-v1.html">404 Error v.1</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="404-v2.html">404 Error v.2</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="404-v3.html">404 Error v.3</a>
                        </li>
                    </ul>
                    </li>
                </ul>
                </li>
                <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Account</a>
                <ul class="dropdown-menu">
                    <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Auth pages</a>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" href="account-signin.html">Sign In</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="account-signup.html">Sign Up</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="account-signinup.html">Sign In / Up</a>
                        </li>
                        <li>
                        <a class="dropdown-item" href="account-password-recovery.html">Password Recovery</a>
                        </li>
                    </ul>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-overview.html">Overview</a>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-settings.html">Settings</a>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-billing.html">Billing</a>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-orders.html">Orders</a>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-earnings.html">Earnings</a>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-chat.html">Chat (Messages)</a>
                    </li>
                    <li>
                    <a class="dropdown-item" href="account-favorites.html">Favorites (Wishlist)</a>
                    </li>
                </ul>
                </li>
                <li class="nav-item">
                <a class="nav-link" href="components/typography.html">UI Kit</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="docs/getting-started.html">Docs</a>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" to="/borrower">
                        <a>ผู้กู้ยืม</a>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/invest">
                        <a>ผู้ลงทุน</a>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/realestate">
                        <a>ทรับย์สิน Interbank Credit</a>
                    </router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.facebook.com/INTERLOAN9?locale=th_TH">
                        <a>Facebook Interbank Credit</a>
                    </a>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/contact">
                        <a>ติดต่อเรา และ ปรึกษา</a>
                    </router-link>
                </li>
            </ul>
            <!-- <div class="d-sm-none p-3 mt-n3">
                <a class="btn btn-primary w-100  mb-1" href="https://themes.getbootstrap.com/product/around-multipurpose-template-ui-kit/" target="_blank" rel="noopener">
                <i class="ai-cart fs-xl me-2 ms-n1"></i>Buy now</a>
            </div> -->
            </nav>
        </div>
    </header>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
    data () {
        return {
            envTelOne: process.env.VUE_APP_CT_TEL_ONE,
            envLineName: process.env.VUE_APP_ST_LINE_NAME,
            envLineID: process.env.VUE_APP_ST_LINE_ID,
            envLineURL: process.env.VUE_APP_ST_LINE_URL,
        }
    },
    // created
    created() {
        // into
        function into() {

        }
        setTimeout(function () {
            into();
        }, 150);
    },
})
export default class HeaderPageOne extends Vue { }

</script>

<style scoped lang="scss">
    ul .nav-link {
        font-weight: 600 !important;
    }
    
    @media screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2){
        .navbar-toggler {
            margin-left: 18px;
        }
    }
    
</style>
  
