<template>
  <!-- in vue 2.x you need wrap these buttons in a div -->
  <button class="carousel-control left" @click="$emit('prev')">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="14"
      height="14" x="0" y="0" viewBox="0 0 55.753 55.753" style="enable-background:new 0 0 512 512" xml:space="preserve"
      class="">
      <g>
        <path
          d="M12.745 23.915c.283-.282.59-.52.913-.727L35.266 1.581a5.4 5.4 0 0 1 7.637 7.638L24.294 27.828l18.705 18.706a5.4 5.4 0 0 1-7.636 7.637L13.658 32.464a5.367 5.367 0 0 1-.913-.727 5.367 5.367 0 0 1-1.572-3.911 5.369 5.369 0 0 1 1.572-3.911z"
          fill="#ffffff" opacity="1" data-original="#000000"></path>
      </g>
    </svg>
  </button>
  <!-- <button class="carousel-control left" @click="$emit('prev')">Prev</button> -->
  <button class="carousel-control right" @click="$emit('next')">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="14"
      height="14" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"
      class="">
      <g>
        <path
          d="M385.1 219.9 199.2 34c-20-20-52.3-20-72.3 0s-20 52.3 0 72.3L276.7 256 126.9 405.7c-20 20-20 52.3 0 72.3s52.3 20 72.3 0l185.9-185.9c19.9-19.9 19.9-52.3 0-72.2z"
          fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
      </g>
    </svg>
  </button>
  <!-- <button class="carousel-control right" @click="$emit('next')">Next</button> -->
</template>
  
<script>
export default {
  emits: ["prev", "next"], // vue v3
};
</script>
<style scoped>
.carousel-control {
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #2aaae1;
  border: none;
  display: inline-block;
  position: absolute;
  height: 50px;
  width: 70px;
  top: calc(50% - 25px);
  color: #f3f3f3;
  cursor: pointer;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

@media screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .carousel-control {
    height: 36px;
    width: 26px;
  }
}
</style>