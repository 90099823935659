<template>
     <section class="pt-5 pb-5 wealth-view">
        <div class="container">
            <div class="row text-center">
                <div class="col-md-12  align-self-center">
                    <h2 class="mb-4">ทรัพย์หลักประกันของ Interbank Credit</h2>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4">
                    <div class="card border-1 bg-secondary">
                        <img class="card-img-top" src="/layouts/templates/roofing_info/assets/uploads/page/realestate/what/what-1.png" alt="Post image">
                        <div class="card-body pb-4 text-center">
                            <h4 class="h5 card-title mb-1 desc_text">
                                ทาวน์เฮ้าส์ 2 ชั้น 2 คูหา
                            </h4>
                            <div class="mt-3 mb-3">
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fa fa-home me-2"></i>รหัสทรัพย์ :</b>2023615
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-map-marker-alt me-2"></i>ณ ที่ :</b>อ.บางบัวทอง จ.นนทบุรี
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-expand me-2"></i>พื้นที่ :</b>32 ตารางวา
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="far fa-credit-card me-2"></i>มูลค่าทรัพย์ :</b>2,400,000 บาท
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="far fa-credit-card me-2"></i>วงเงินขายฝาก :</b>1,350,000 บาท
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-calculator me-2"></i>ดอกเบี้ย :</b>202,500 บาท/ปี (15%)(สัญญา 1-2 ปี)
                                </p>
                            </div>
                            <router-link to="/contact">
                                <a>
                                    <b>ติดต่อเรา คลิก..! <i class="mdi mdi-keyboard-return ms-1"></i></b>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card border-1 bg-secondary">
                        <img class="card-img-top" src="/layouts/templates/roofing_info/assets/uploads/page/realestate/what/what-2.png" alt="Post image">
                        <div class="card-body pb-4 text-center">
                            <h4 class="h5 card-title mb-1 desc_text">
                                ทาวน์เฮ้าส์ 2 ชั้น 2 คูหา
                            </h4>
                            <div class="mt-3 mb-3">
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fa fa-home me-2"></i>รหัสทรัพย์ :</b>2023615
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-map-marker-alt me-2"></i>ณ ที่ :</b>อ.บางบัวทอง จ.นนทบุรี
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-expand me-2"></i>พื้นที่ :</b>32 ตารางวา
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="far fa-credit-card me-2"></i>มูลค่าทรัพย์ :</b>2,400,000 บาท
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="far fa-credit-card me-2"></i>วงเงินขายฝาก :</b>1,350,000 บาท
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-calculator me-2"></i>ดอกเบี้ย :</b>202,500 บาท/ปี (15%)(สัญญา 1-2 ปี)
                                </p>
                            </div>
                            <router-link to="/contact">
                                <a>
                                    <b>ติดต่อเรา คลิก..! <i class="mdi mdi-keyboard-return ms-1"></i></b>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card border-1 bg-secondary">
                        <img class="card-img-top" src="/layouts/templates/roofing_info/assets/uploads/page/realestate/what/what-3.png" alt="Post image">
                        <div class="card-body pb-4 text-center">
                            <h4 class="h5 card-title mb-1 desc_text">
                                ทาวน์เฮ้าส์ 2 ชั้น 2 คูหา
                            </h4>
                            <div class="mt-3 mb-3">
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fa fa-home me-2"></i>รหัสทรัพย์ :</b>2023615
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-map-marker-alt me-2"></i>ณ ที่ :</b>อ.บางบัวทอง จ.นนทบุรี
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-expand me-2"></i>พื้นที่ :</b>32 ตารางวา
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="far fa-credit-card me-2"></i>มูลค่าทรัพย์ :</b>2,400,000 บาท
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="far fa-credit-card me-2"></i>วงเงินขายฝาก :</b>1,350,000 บาท
                                </p>
                                <p class="fs-14 mb-2">
                                    <b class="me-2"><i class="fas fa-calculator me-2"></i>ดอกเบี้ย :</b>202,500 บาท/ปี (15%)(สัญญา 1-2 ปี)
                                </p>
                            </div>
                            <router-link to="/contact">
                                <a>
                                    <b>ติดต่อเรา คลิก..! <i class="mdi mdi-keyboard-return ms-1"></i></b>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4 text-center">
                <div class="col-md-12  align-self-center">
                    <router-link to="realestate">
                        <a class="btn btn-lg btn-primary w-100 w-sm-auto mb-2 mb-sm-0 me-sm-1" href="#">ดูทังหมด คลิก..!</a>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
    .wealth-view i {
        color: #2cabe1;
    }
    .wealth-view h4 {
        color: #005478;
    }
    .wealth-view .bg-secondary {
        --ar-bg-opacity: 1;
        background-color: #2aaae10c !important;
    }
    .wealth-view .card {
        margin-bottom: 15px;
    }

</style>