<template>
  <footer class="footer .dark-mode bg-dark pb-3 pt-sm-3 py-md-1">
    <div class="container pb-4 pt-5">
      <div>
        <div class="row">
          <div class="col-md-4">
              <router-link class="navbar-brand pe-sm-3" to="/">
                <a>
                  <img src="/layouts/templates/roofing_info/assets/uploads/logo/logo-w.png" width="120" alt="">
                </a>
              </router-link>
              <div class="mt-3">
                <p>บริการรับจำนอง ผากขาย ไถ่ถอน เพิ่มวงเงิน บ้าน ที่ดิน คอนโดมิเนียม <br>อาคารพาณิชย์และอสังหาริมทรัพย์ทุกชนิด
                  บรการฉับไว ถูกต้องตามกฏหมาย</p>
                <b>ที่อยู่ : {{ envMapText }}</b>
              </div>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4 text-md-end text-start mb-md-0 mb-5">
            <div class="mt-5">
              <h2 class="text-white fs-24">ติดต่อ Interbank Credit</h2>
              <div>
                <b>ส่ายด่วน 24 ชม. :</b> {{ envTelOne }}
                <br>
                <b>สายผู้ลงทุ่น :</b> {{ envTelTwo }}
                <br>
                <b>สายกู้ยิมด่วน..! :</b> {{ envTelThee }}
              </div>
              <div class="mt-3">
                <b>อีเมล :</b> {{ envMail }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-flex align-items-center justify-content-between pb-1 pb-md-0 mb-4 mb-md-5 mt-3">
        <nav class="nav justify-content-center justify-content-md-start pb-sm-2 pb-md-0 mb-4 mb-md-0 ms-md-n3">
          <router-link to="/borrower">
            <a class="nav-link py-1 px-0 mx-3">ผู้กู้ยืม</a>
          </router-link>
          <router-link to="/invest">
            <a class="nav-link py-1 px-0 mx-3">ผู้ลงทุน</a>
          </router-link>
          <router-link to="/realestate">
            <a class="nav-link py-1 px-0 mx-3">ทรับย์สิน Interbank Credit</a>
          </router-link>
          <router-link to="/contact">
            <a class="nav-link py-1 px-0 mx-3">ติดต่อเรา และ ปรึกษา</a>
          </router-link>
        </nav>
        <div class="d-flex justify-content-center .justify-content-md-start me-md-n2">
          <div>
            <a class="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle mx-2" href="#" style="background-color: #09a428;">
              <i class="fab fa-line"></i>
            </a>
            <a class="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle mx-2" href="#" style="background-color: #005cce;">
              <i class="ai-facebook"></i>
            </a>
            <!-- <a class="btn btn-icon btn-sm btn-secondary btn-twitter rounded-circle mx-2" href="#" style="background-color: #e40000;">
              <i class="ai-youtube"></i>
            </a> -->
          </div>
        </div>
      </div>
      <div class="d-md-flex align-items-center justify-content-between text-center text-md-start">
          <a class="nav-link d-inline-block .text-muted fs-sm text-decoration-none order-md-2 py-1 px-0 mb-3 mb-md-0" href="https://www.naelike.com">Fastwork NaeLink
          <p class="fs-sm order-md-1 mb-0">
            <span class="opacity-70">©2023 Interbank Credit V100 </span>
            <b>
              <a class="nav-link d-inline fw-normal p-0 ms-2" href="https://fastwork.co/user/somkhane/web-development-48312694" target="_blank" rel="noopener">Development Fastwork Naelike </a>
            </b>
          </p>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      envMapText: process.env.VUE_APP_CT_MAP_TEXT,
      envMail: process.env.VUE_APP_CT_MAIL,
      envTelOne: process.env.VUE_APP_CT_TEL_ONE,
      envTelTwo: process.env.VUE_APP_CT_TEL_TWO,
      envTelThee: process.env.VUE_APP_CT_TEL_THEE,
      // envUrlLine: VUE_APP_ST_LINE_URL,
      // envUrlFacebook: VUE_APP_ST_FACEBOOK_URL,
      // envUrlYoutube: VUE_APP_ST_YOUTUBE_URL,
    }
  },
  // created
  created() {
    // into
    function into() {
      // console.log(process.env.VUE_APP_MAP_GOOGLE);
      
    }
    setTimeout(function () {
      into();
    }, 400);
  },
})
export default class FooterPageOne extends Vue { }
</script>

<style scoped lang="scss">
  footer {
    background-color: #2aace3 !important;
  }

  footer {
    color: #fff;
  }

  footer a {
    color: #fff;
  }
</style>
