import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/Home_view.vue'
// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('../views/contact/Contact_view.vue')
  },
  {
    path: '/borrower',
    name: 'Borrower',
    component: () => import('../views/borrower/Borrower_view.vue')
  },
  {
    path: '/invest',
    name: 'Invest',
    component: () => import('../views/invest/Invest_view.vue')
  },
  {
    path: '/realestate',
    name: 'Realestate',
    component: () => import('../views/realestate/Realestates_view.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  // return false
  // https://www.youtube.com/watch?v=Pcrzy1f1fK4
  // https://router.vuejs.org/guide/advanced/navigation-guards.html
  // ...
  // console.log(to);
  next();
})

export default router
