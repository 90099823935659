<template>
     <section class="pt-5 pb-5 sectyoutubeion-helphome-bk-cp-40">
        <div class="container">
            <div class="row">
                <div class="col-md-6  align-self-center  order-md-1 order-2">
                    <h2 class="mb-4">เราช่วยคุณประหยัดดอกเบี้ยถึง 6% ต่อปี</h2>
                    <div>
                        <h5>ตัวอย่าง ส่วนต่างดอกเบี้ยระหว่าง 9% และ 15% ต่อปี</h5>
                        <ul>
                            <li>
                                เงินต้น 1,000,000 บาท
                            </li>
                            <li>
                                ดอกเบี้ย 9% = 90,000 บาท
                            </li>
                            <li>
                                ดอกเบี้ย 15% = 150,000 บาท
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h5>ส่วนต่างสูงถึง 60,000 บาท/ปี</h5>
                        <p>
                            Interbank Credit ขายฝาก
                            <br>
                            ดอกเบี้ยต่ำ 0.75% ต่อเดือน
                        </p>
                    </div>
                    <div class="mt-4">
                        <router-link to="contact">
                            <a class="btn btn-lg btn-primary w-100 w-sm-auto mb-2 mb-sm-0 me-sm-1" href="#">ติดต่อเรา คลิก..!</a>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-6 align-self-center mb-md-0 mb-4 order-md-2 order-1">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/help/help-img.png" width="" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
  
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
  .sectyoutubeion-helphome-bk-cp-40 {
      /* background-color: #3e3e3e; */
      /* background: linear-gradient(110deg, #fdcd3b 60%, #fff 60%); */
      background: linear-gradient(100deg, #2aaae130 33%, #fff 57%, #fff 67%);
      padding: 40px 0px;
  }
  .sectyoutubeion-helphome-bk-cp-40 h5 {
    color: #005478;
  }
</style>
  
