<template>
    <section class="map-pb-e-10">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.248099591979!2d100.55975777509157!3d13.824135386575236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29c57fb3e3e35%3A0x72cb5075c7343595!2z4Lin4Li04Lig4Liy4Lin4LiU4Li14LmA4Lie4Lil4Liq!5e0!3m2!1sth!2sth!4v1698572824492!5m2!1sth!2sth" width="100%" height="380" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
</template>
  
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
    .map-pb-e-10 iframe {
        margin-bottom: -10px;
    }
</style>