<template>
    <section class="section-bk-cp-40">
        <div class="container">
            <div class="row">
                <div class="col-md-5 align-self-center">
                    <div class="row">
                        <div class="col-md-4 col-6 mb-1 mt-1">
                            <img class="rounded"
                                src="/layouts/templates/roofing_info/assets/uploads/page/home/train/train-1.png"
                                width="100%" alt="">
                        </div>
                        <div class="col-md-4 col-6 mb-1 mt-1">
                            <img class="rounded"
                                src="/layouts/templates/roofing_info/assets/uploads/page/home/train/train-2.png"
                                width="100%" alt="">
                        </div>
                        <div class="col-md-4 col-6 mb-1 mt-1">
                            <img class="rounded"
                                src="/layouts/templates/roofing_info/assets/uploads/page/home/train/train-3.png"
                                width="100%" alt="">
                        </div>
                        <div class="col-md-4 col-6 mb-1 mt-1">
                            <img class="rounded"
                                src="/layouts/templates/roofing_info/assets/uploads/page/home/train/train-4.png"
                                width="100%" alt="">
                        </div>
                        <div class="col-md-4 col-6 mb-1 mt-1">
                            <img class="rounded"
                                src="/layouts/templates/roofing_info/assets/uploads/page/home/train/train-5.png"
                                width="100%" alt="">
                        </div>
                        <div class="col-md-4 col-6 mb-1 mt-1">
                            <img class="rounded"
                                src="/layouts/templates/roofing_info/assets/uploads/page/home/train/train-6.png"
                                width="100%" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-7 align-self-center">
                    <h2>อบรมด้านรับจำนอง-ขายฝาก</h2>
                    <p>
                        Land for Loan แลนด์ฟอร์โลน นอกจากเป็นหนึ่งในผู้นำด้านธุรกรรมจำนอง-ขายฝาก
                        เรายังเป็นศูนย์อบรมด้านธุรกรรมจำนอง – ขายฝากอสังหาริมทรัพย์ ของเมืองไทย
                        ที่มีวัตถุประสงค์พัฒนาบุคลากรและผู้ที่สนใจในธุรกรรมดังกล่าว ให้เป็นผู้เชี่ยวชาญระดับมืออาชีพ
                        เพื่อเตรียมความพร้อมในการทำธุรกิจด้านอสังหาริมทรัพย์ได้จริง
                        รวมถึงผู้ที่ต้องการลงทุนและหาประสบการณ์เฉพาะด้านของอสังหาริมทรัพย์
                    </p>
                    <b>
                        เก็บตกบรรยากาศของคอร์ส <br> เสือนอนกินด้วยอสังหาฯ จำนอง-ขายฝาก
                    </b>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script lang="js">
// Options
import { Options, Vue } from "vue-class-component";
// Options
@Options({
    data() {
        return {};
    },
})
export default class Home_view extends Vue { }
</script>

<style>
    .section-bk-cp-40 {
        background-color: #2aaae130;
        padding: 40px 0px;
    }
</style>
  